html {
  font-family: 'Roboto', sans-serif;
}

html,
body,
div#root {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex: 1 0;
}

@-moz-document url-prefix() {
  html,
  body,
  div#root {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex: 1 0;
    overflow: auto;
  }
}
