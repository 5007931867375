.outlineButton {
  border-radius: 99px !important;
  width: auto !important;
  background: #ffffff !important;
  border: 1px solid #40ae96 !important;
  color: #40ae96 !important;
}
.fillButton {
  margin-left: 15px !important;
  background: #40ae96 !important;
  border-radius: 99px !important;
  align-items: center !important;
  text-align: center !important;
  width: auto !important;
  border: 1px solid #40ae96 !important;
  color: #ffffff;
}
.fillButton:disabled {
  background: #d7d7d7 !important;
  border: 1px solid #b8b5b5 !important;
}
.editIcon {
  width: 0.7em;
  margin-right: 5px;
}
.disabled {
  pointer-events: none;
  color: #aaa;
  background: #f5f5f5;
}
